import {
	FaBatteryEmpty,
	FaClock,
	FaEye,
	FaIndustry,
	FaMailBulk,
	FaNewspaper,
	FaShippingFast,
	FaSlash,
	FaStar,
	FaTable,
	FaTag,
	FaUserClock,
	FaWpforms,
} from 'react-icons/fa';

import { getGlsLabel } from './classes/GLS.utils';
import {
	getOrderData,
	OrderDataAction,
	orderToShipId,
	updateStatus,
} from './classes/Magento.utils';
import { getMyParcelLabel } from './classes/MyParcel.utils';
import { handleLabelPrint } from './classes/Shippr.utils';
import { ActionProps } from './components/Action/Action';
import BarBottomShipping from './components/BarBottom/BarBottomShipping';
import MainShippingWindow from './components/MainShippingWindow/MainShippingWindow';
import Sidebar from './components/Sidebar/Sidebar';

const shipActions: ActionProps[] = [
	{
		icon: FaEye,
		actionId: 'order_view',
		label: 'Show order details',
		group: 'info',
		handler: async (orderId, context) => {
			await getOrderData(orderId, context);

			return true;
		},
	},
	{
		icon: FaShippingFast,
		actionId: 'label_shipall-gls',
		label: 'GLS - Ship All',
		group: 'gls',
		handler: async (orderId, context) => {
			const shipmentId = await orderToShipId(orderId, context, true);
			if (!shipmentId) return false;

			const label = await getGlsLabel(shipmentId, orderId, context);
			if (!label) return false;

			const printedLabel = handleLabelPrint(label, orderId, context);

			return printedLabel;
		},
	},
	{
		icon: FaTag,
		actionId: 'label_orderid',
		label: 'GLS - Label Copy',
		group: 'gls',
		handler: async (orderId, context) => {
			const shipmentId = await orderToShipId(orderId, context, false);
			if (!shipmentId) return false;

			const label = await getGlsLabel(shipmentId, orderId, context);
			if (!label) return false;

			const printedLabel = handleLabelPrint(label, orderId, context);

			return printedLabel;
		},
	},
	{
		icon: FaMailBulk,
		actionId: 'label_shipall-myparcel',
		label: 'Brievenbus NL - Ship All',
		group: 'myparcel',
		handler: async (orderId, context) => {
			const shipmentId = await orderToShipId(orderId, context, true);
			if (!shipmentId) return false;

			const label = await getMyParcelLabel(shipmentId, orderId, context);
			if (!label) return false;

			const printedLabel = handleLabelPrint(label, orderId, context);

			return printedLabel;
		},
	},
	{
		icon: FaTag,
		actionId: 'label_copy-myparcel',
		label: 'Brievenbus NL - Label Copy',
		group: 'myparcel',
		handler: async (orderId, context) => {
			const shipmentId = await orderToShipId(orderId, context, false);
			if (!shipmentId) return false;

			const label = await getMyParcelLabel(shipmentId, orderId, context);
			if (!label) return false;

			const printedLabel = handleLabelPrint(label, orderId, context);

			return printedLabel;
		},
	},
	{
		icon: FaTable,
		actionId: 'label_copy-myparcelintl',
		label: 'Export - Label Copy',
		group: 'myparcel',
		handler: async (orderId, context) => {
			await getOrderData(orderId, context, OrderDataAction.export, true);

			return true;
		},
	},
	{
		icon: FaIndustry,
		actionId: 'status_in-production',
		label: 'Status: In behandeling',
		group: 'status',
		handler: async (orderId, context) => {
			return updateStatus(orderId, context, 'in_production');
		},
	},
	{
		icon: FaSlash,
		actionId: 'status_cancelled',
		label: 'Status: Cancel',
		group: 'status',
		isPowerMode: true,
		handler: async (orderId, context) => {
			return updateStatus(orderId, context, 'canceled');
		},
	},
	{
		icon: FaClock,
		actionId: 'status_delay-short',
		label: 'Status: Vertraging (kort)',
		group: 'status',
		isPowerMode: true,
		handler: async (orderId, context) => {
			return updateStatus(orderId, context, 'delay_short');
		},
	},
	{
		icon: FaUserClock,
		actionId: 'status_delay-long',
		label: 'Status: Vertraging (lang)',
		group: 'status',
		isPowerMode: true,
		handler: async (orderId, context) => {
			return updateStatus(orderId, context, 'delay_long');
		},
	},
	{
		icon: FaStar,
		actionId: 'status_exception',
		label: 'Status: Uitzondering',
		group: 'status',
		isPowerMode: true,
		handler: async (orderId, context) => {
			return updateStatus(orderId, context, 'exception');
		},
	},
	{
		icon: FaBatteryEmpty,
		actionId: 'status_outofstock',
		label: 'Status: Geen Voorraad',
		group: 'status',
		isPowerMode: true,
		handler: async (orderId, context) => {
			return updateStatus(orderId, context, 'outofstock');
		},
	},
	{
		icon: FaWpforms,
		actionId: 'status_admin_submitted_topem',
		label: 'Status: Ingevoerd (TopEmaille)',
		group: 'status',
		isPowerMode: true,
		handler: async (orderId, context) => {
			return updateStatus(orderId, context, 'admin_submitted_topem');
		},
	},
	{
		icon: FaNewspaper,
		actionId: 'warehouse_resend',
		label: 'Magazijnmail opnieuw versturen',
		group: 'internal',
		isPowerMode: true,
		handler: async (orderId, context) => {
			const { mageOrderApi } = context;

			const orderData = await getOrderData(orderId, context);

			if (!orderData) return false;

			await mageOrderApi.resendWarehouseMail(orderData.techId, context);

			return true;
		},
	},
];

function ShippingApp() {
	return (
		<>
			<MainShippingWindow />
			<Sidebar actions={shipActions} />
			<BarBottomShipping />
		</>
	);
}

export default ShippingApp;
