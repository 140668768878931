import { useContext } from 'react';
import { FaCogs } from 'react-icons/fa';

import { ModalContext } from '../../contexts/ModalContext';
import Button from '../Button/Button';

function SettingsButton() {
	const { toggleVisible } = useContext(ModalContext);

	return (
		<Button
			onClick={() => {
				toggleVisible();
			}}
		>
			<FaCogs />
		</Button>
	);
}

export default SettingsButton;
