import cx, { Argument } from 'classnames';

import { getShape } from '../../classes/Warehouse.utils';

interface Props {
	width: string;
	height: string;
	shape: string;
	className?: Argument;
}

function ProductSize(props: Props) {
	const { width, height, shape, className } = props;

	return (
		<div className={cx(className)}>{`${getShape(shape)} ${
			parseInt(width) / 10
		} x ${parseInt(height) / 10} cm.`}</div>
	);
}

export default ProductSize;
