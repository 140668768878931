import { FaCoins, FaRegNoteSticky } from 'react-icons/fa6';
import { ImQrcode } from 'react-icons/im';

import { ActionProps } from './components/Action/Action';
import BarBottomWarehouse from './components/BarBottom/BarBottomWarehouse';
import MainWarehouseWindow from './components/MainWarehouseWindow/MainWarehouseWindow';
import Sidebar from './components/Sidebar/Sidebar';
import { LogLevel } from './contexts/LoggerContext';

const warehouseActions: ActionProps[] = [
	{
		icon: ImQrcode,
		actionId: 'qr_generate',
		label: 'Generate QR code',
		group: 'qr',
		handler: async () => true,
	},
	{
		icon: FaRegNoteSticky,
		actionId: 'inventory_manage',
		label: 'Manage inventory',
		group: 'inventory',
		handler: async (result, context) => {
			const { mageWarehouseApi, setProduct } = context;

			const sku = result.includes('catalogsearch')
				? result.split('?q=')[1]
				: result;
			const productData = await mageWarehouseApi.getFullProductData(sku);
			// @ts-ignore
			setProduct(productData?.message ? false : productData);

			return true;
		},
	},
	{
		icon: FaCoins,
		actionId: 'inventory_minusone',
		label: 'Inventory -1',
		group: 'inventory',
		handler: async (result, context) => {
			const { mageWarehouseApi, setProduct, addLog } = context;

			const sku = result.includes('catalogsearch')
				? result.split('?q=')[1]
				: result;
			const productData = await mageWarehouseApi.getFullProductData(sku);
			// @ts-ignore
			setProduct(productData?.message ? false : productData);

			const data = {
				qty: productData.qty - 1,
			};
			const response = await mageWarehouseApi.updateProduct(sku, data);
			for (const resp of response) {
				addLog(
					'WARE',
					resp,
					resp.includes(sku) ? LogLevel.bright : LogLevel.normal,
				);
			}

			return true;
		},
	},
];

function WarehouseApp() {
	return (
		<>
			<MainWarehouseWindow />
			<Sidebar actions={warehouseActions} />
			<BarBottomWarehouse />
		</>
	);
}

export default WarehouseApp;
