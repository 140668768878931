import { createContext, PropsWithChildren, useState } from 'react';

interface ModalState {
	visible: boolean;
	toggleVisible: () => void;
}

const defaultModalState: ModalState = {
	visible: false,
	toggleVisible: () => {
		console.error('Context not available');
	},
};
export const ModalContext = createContext(defaultModalState);

function ModalContextProvider(props: PropsWithChildren) {
	const { children } = props;

	const [visible, setVisible] = useState(defaultModalState.visible);

	const toggleVisible = () => {
		setVisible((prevVisible) => {
			return !prevVisible;
		});
	};

	const context: ModalState = {
		...defaultModalState,
		visible,
		toggleVisible,
	};

	return (
		<ModalContext.Provider value={context}>
			{children}
		</ModalContext.Provider>
	);
}

export default ModalContextProvider;
