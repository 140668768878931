import { useContext, useRef } from 'react';
import toast from 'react-hot-toast';
import { Form, Formik, FormikHelpers } from 'formik';
import QrCodeWithLogo from 'qrcode-with-logos';

import { MagentoWarehouseApi } from '../../classes/MagentoWarehouseApi';
import { getShape } from '../../classes/Warehouse.utils';
import { MageContext } from '../../contexts/MageContext';
import { PrinterContext } from '../../contexts/PrinterContext';
import Button from '../Button/Button';
import FormInput from '../FormInput/FormInput';
import SomethingWentWrong from '../SomethingWentWrong/SomethingWentWrong';

function downloadCanvasAsImage(pngBase64: string) {
	const downloadLink = document.createElement('a');
	downloadLink.setAttribute('download', 'CanvasAsImage.png');
	const url = pngBase64.replace(
		/^data:image\/png/,
		'data:application/octet-stream',
	);
	downloadLink.setAttribute('href', url);
	downloadLink.click();
}

interface Props {
	labelWidth?: number;
	labelHeight?: number;
	dpi?: number;
	fontHeading?: string;
	fontRegular?: string;
	download?: boolean;
	showOutput?: boolean;
	onRender?: (base64: string, sku: string) => void;
}

interface Values {
	sku: string;
}

function QRLabelGenerator(props: Props) {
	const {
		labelWidth = 89,
		labelHeight = 41,
		dpi = 100,
		fontHeading = 'Impact',
		fontRegular = 'Arial',
		download,
		showOutput,
		onRender,
	} = props;

	const imageRef = useRef<HTMLImageElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const { hasPrintServerConnection } = useContext(PrinterContext);
	const { hasStoreConnection, getConfig } = useContext(MageContext);

	const mageApi = new MagentoWarehouseApi(getConfig());

	if (hasPrintServerConnection !== 'connected' || !hasStoreConnection) {
		return (
			<SomethingWentWrong
				requiresStore={hasStoreConnection}
				requiresPrinter={hasPrintServerConnection !== 'connected'}
				componentName='QRLabel Generator'
			/>
		);
	}

	const onSubmit = async (
		values: Values,
		formikHelpers: FormikHelpers<Values>,
	) => {
		const { sku } = values;

		const productData = await mageApi.getProductData(sku);

		if (!productData || productData.message) {
			toast.error('Could not load product data, aborting');
			formikHelpers.setSubmitting(false);

			return;
		}

		const { name: productName, width, height, shape } = productData;

		return new Promise<void>((resolve) => {
			requestAnimationFrame(() => {
				requestAnimationFrame(() => {
					new QrCodeWithLogo({
						content: `https://www.emaillegigant.nl/catalogsearch/result/?q=${sku.toUpperCase()}`,
						width: 39 * dpi,
						image: imageRef?.current ?? undefined,
						nodeQrCodeOptions: {
							margin: 0.5,
						},
					})
						.toImage()
						.then(() => {
							if (!imageRef.current) return;
							imageRef.current.onload = () => {
								if (!imageRef.current || !canvasRef.current) {
									toast.error(
										'Something went horribly wrong generating the QR Label',
									);

									return;
								}

								const context =
									canvasRef.current.getContext('2d');

								if (!context) return;

								context.clearRect(
									0,
									0,
									labelWidth * dpi,
									labelHeight * dpi,
								);

								context.fillStyle = 'white';
								context.fillRect(
									0,
									0,
									labelWidth * dpi,
									labelHeight * dpi,
								);
								context.fillStyle = 'black';

								context.drawImage(
									imageRef.current,
									1 * dpi,
									1 * dpi,
								);

								context.font = `${6 * dpi}px ${fontHeading}`;
								context?.fillText(
									sku.toUpperCase(),
									42 * dpi,
									6.5 * dpi,
								);

								const productNameArray = productName.split(' ');
								const lines: string[] = [''];
								productNameArray.forEach((part) => {
									// Check last line length
									const lastLine = lines[lines.length - 1];
									const partLength = part.length;

									if (lastLine.length + partLength < 22) {
										lines[lines.length - 1] += ` ${part}`;
									} else {
										lines.push(part);
									}
								});

								context.font = `${4 * dpi}px ${fontRegular}`;
								lines.forEach((line, i) => {
									if (i > 5) return;

									context?.fillText(
										line.trim(),
										42 * dpi,
										12 * dpi + i * (4 * dpi),
									);
								});

								context.font = `${3 * dpi}px ${fontHeading}`;
								context?.fillText(
									`${getShape(shape)} ${
										parseInt(width) / 10
									} x ${parseInt(height) / 10} cm.`,
									labelWidth * dpi - 18 * dpi,
									labelHeight * dpi - 1.5 * dpi,
								);

								const pngBase64 =
									canvasRef.current.toDataURL('image/png');
								onRender && onRender(pngBase64, sku);
								download && downloadCanvasAsImage(pngBase64);

								formikHelpers.setSubmitting(false);
								resolve();
							};
						});
				});
			});
		});
	};

	return (
		<>
			<Formik
				initialValues={{ sku: '' }}
				onSubmit={onSubmit}
				validate={(values: Partial<Values>) => {
					const errors: Partial<Values> = {};

					if (!values.sku) {
						errors.sku = 'SKU is required';
					}

					return errors;
				}}
			>
				{({ handleSubmit, isSubmitting }) => (
					<Form onSubmit={handleSubmit}>
						<h1>Generate QR Label</h1>
						<FormInput label='SKU' name='sku' />
						<Button type='submit' disabled={isSubmitting}>
							Submit
						</Button>
					</Form>
				)}
			</Formik>
			<img ref={imageRef} style={{ display: 'none' }} />
			<canvas
				ref={canvasRef}
				width={labelWidth * dpi}
				height={labelHeight * dpi}
				style={{
					border: '1px solid #000',
					display: showOutput ? 'block' : 'none',
				}}
			/>
		</>
	);
}

export default QRLabelGenerator;
