import { InputHTMLAttributes } from 'react';
import cx from 'classnames';
import { useField } from 'formik';

import styles from './FormInput.module.scss';

interface Props
	extends Omit<
		InputHTMLAttributes<HTMLInputElement>,
		'defaultValue' | 'className' | 'onChange'
	> {
	label: string;
	name: string;
	defaultValue?: string;
	className?: string;
	isNumeric?: boolean;
	hasDecimals?: boolean;
}

function FormInput(props: Props) {
	const { label, name, className, isNumeric, hasDecimals } = props;

	const [field, _meta, helpers] = useField(name);

	return (
		<label className={cx(styles.container, className)}>
			<span className={styles.label}>{label}</span>
			<input
				className={styles.input}
				{...props}
				{...field}
				onChange={(event) => {
					const value = isNumeric
						? hasDecimals
							? event.target.value
							: parseInt(event.target.value)
						: event.target.value;

					helpers.setValue(
						// @ts-ignore
						isNumeric ? (isNaN(value) ? 0 : value) : value,
					);
				}}
				value={field.value}
			/>
		</label>
	);
}

export default FormInput;
