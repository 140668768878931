import { MageConfig } from '../contexts/MageContext';

export class MagentoApi {
	private baseUrl: string;
	private token: string;

	constructor(config: MageConfig) {
		const { siteUrl, restBase, accessToken } = config;

		this.baseUrl = `${siteUrl}/${restBase}`;
		this.token = accessToken ?? '';
	}

	public async checkConnection() {
		// TODO: Make HG-01 a config thing
		const response = await this._call('stockStatuses/HG-01', null, true);

		if (!response || response.status !== 200) {
			console.error('Could not connect');

			return false;
		}

		return true;
	}

	protected async _call(url: string, payload?: any, raw?: boolean) {
		const apiUrl = `${this.baseUrl}/${url}`;

		const requestOptions: RequestInit = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${this.token}`,
			},
		};

		if (payload) {
			requestOptions.method = 'POST';
			requestOptions.body = JSON.stringify(payload);
		}

		const data = await fetch(apiUrl, requestOptions)
			.then((response) => {
				return raw ? response : response.json();
			})
			.catch((err) => {
				console.warn(err);
			});

		return data;
	}
}
