import { PropsWithChildren } from 'react';

import styles from './ConfigViewPage.module.scss';

interface Props extends PropsWithChildren {
	title: string;
}

function ConfigViewPage(props: Props) {
	const { title, children } = props;

	return (
		<div>
			<h1>{title}</h1>
			<div className={styles.container}>{children}</div>
		</div>
	);
}

export default ConfigViewPage;
