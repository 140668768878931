import cx from 'classnames';

import ButtonAppMode from '../ButtonAppMode/ButtonAppMode';
import ButtonFullScreen from '../ButtonFullScreen/ButtonFullScreen';
import ButtonSettings from '../ButtonSettings/ButtonSettings';
import styles from './SettingsBar.module.scss';

function SettingsBar() {
	return (
		<div className={cx(styles.container)}>
			<ButtonFullScreen />
			<ButtonAppMode />
			<ButtonSettings />
		</div>
	);
}

export default SettingsBar;
