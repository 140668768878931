import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

import Button from '../Button/Button';
import styles from './InputAmount.module.scss';

interface Props {
	defaultValue?: number;
	keyboardControlled?: boolean;
	onChange?: (newQty: number) => void;
}

function InputAmount(props: Props) {
	const { defaultValue = 1, keyboardControlled, onChange } = props;

	const [value, setValue] = useState(defaultValue);

	const updateValue = useCallback(
		(newValue: number) => {
			const value = newValue && newValue <= 0 ? 1 : newValue;

			setValue(value);
			onChange && onChange(value);
		},
		[onChange],
	);
	const _onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.value) {
				// @ts-ignore
				updateValue('');

				return;
			}

			const newValue = parseInt(event.target.value);
			updateValue(isNaN(newValue) ? 1 : newValue);
		},
		[updateValue],
	);
	const onKeydown = useCallback(
		(event: KeyboardEvent) => {
			const { code } = event;

			let newValue = value;

			if (code === 'PageDown') newValue = value - 1;
			if (code === 'PageUp') newValue = value + 1;

			if (newValue == 0) {
				newValue = 1;
			}

			updateValue(newValue);

			event.stopPropagation();
		},
		[value, updateValue],
	);

	useEffect(() => {
		keyboardControlled && document.addEventListener('keydown', onKeydown);

		return () => {
			keyboardControlled &&
				document.removeEventListener('keydown', onKeydown);
		};
	}, [onKeydown, keyboardControlled]);

	return (
		<div className={styles.container}>
			<Button
				className={styles.button}
				onClick={() => updateValue(value - 1)}
				disabled={value === 1}
			>
				<FaMinus />
			</Button>
			<input
				type='number'
				inputMode='numeric'
				className={styles.input}
				value={value}
				onChange={_onChange}
			/>
			<Button
				className={styles.button}
				onClick={() => updateValue(value + 1)}
			>
				<FaPlus />
			</Button>
		</div>
	);
}

export default InputAmount;
