// NOTE: Somehow Prettier formatting decides mixed is fine
/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { FaPrint } from 'react-icons/fa';

import { PrinterContext } from '../../contexts/PrinterContext';
import ConnnectionStatus from '../ConnectionStatus/ConnectionStatus';

function PrinterStatus() {
	const { hasPrintServerConnection, printer } = useContext(PrinterContext);

	const notifyStatus = useCallback(() => {
		const toastOpt = { id: 'printerStatus', duration: 5000 };

		hasPrintServerConnection === undefined
			? toast('Checking printserver status...', {
					...toastOpt,
					duration: Infinity,
			  })
			: hasPrintServerConnection === 'versionError'
			? toast.error('The printserver needs to be updated', toastOpt)
			: hasPrintServerConnection === 'connected'
			? printer
				? toast.success(`Printserver connected using ${printer}`, {
						...toastOpt,
						duration: 1500,
				  })
				: toast.error(
						`Printserver connected, but no printer was set`,
						toastOpt,
				  )
			: toast.error(
					`Could not connect to printserver. If it's running, check your configuration.`,
					toastOpt,
			  );
	}, [hasPrintServerConnection, printer]);

	useEffect(() => {
		notifyStatus();
	}, [notifyStatus]);

	return (
		<ConnnectionStatus
			renderIcon={() => (
				<FaPrint
					color={
						hasPrintServerConnection === undefined
							? 'blue'
							: hasPrintServerConnection === 'versionError'
							? 'purple'
							: hasPrintServerConnection === 'connected'
							? printer
								? 'green'
								: 'orange'
							: 'red'
					}
				/>
			)}
			notifyStatus={notifyStatus}
		/>
	);
}

export default PrinterStatus;
