import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useState,
} from 'react';

import { MageOrder } from '../classes/Magento.types';

interface MageOrderContext {
	order: MageOrder | null;
	isExport: boolean;
	isLoading: boolean;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	setOrder: (order: MageOrder | null, isExport?: boolean) => void;
	parcelCount: number;
	setParcelCount: Dispatch<SetStateAction<number>>;
}

const defaultMageOrderState: MageOrderContext = {
	order: null,
	isExport: false,
	isLoading: false,
	setIsLoading: () => undefined,
	setOrder: () => undefined,
	parcelCount: 1,
	setParcelCount: () => undefined,
};

export const MageOrderContext = createContext<MageOrderContext>(
	defaultMageOrderState,
);
function MageOrderContextProvider(props: PropsWithChildren) {
	const { children } = props;
	const [order, setOrder] = useState<MageOrder | null>(null);
	const [isExport, setIsExport] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [parcelCount, setParcelCount] = useState<number>(1);

	const context: MageOrderContext = {
		...defaultMageOrderState,
		order,
		isExport,
		setOrder: (order, isExport) => {
			setOrder(order);
			console.log({ order });
			setIsExport(!!isExport);
			setIsLoading(false);
		},
		isLoading,
		setIsLoading,
		parcelCount,
		setParcelCount,
	};

	return (
		<MageOrderContext.Provider value={context}>
			{children}
		</MageOrderContext.Provider>
	);
}

export default MageOrderContextProvider;
