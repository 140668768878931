import { useContext } from 'react';
import cx from 'classnames';

import { MageOrderContext } from '../../contexts/MageOrderContext';
import InputAmount from '../InputAmount/InputAmount';
import InputScan from '../InputScan/InputScan';
import PrinterStatus from '../PrinterStatus/PrinterStatus';
import StoreStatus from '../StoreStatus/StoreStatus';
import styles from './BarBottom.module.scss';

function BarBottomShipping() {
	const { setParcelCount } = useContext(MageOrderContext);

	return (
		<div className={cx(styles.container)}>
			<div className={cx(styles['sub-container'])}>
				<div className={cx(styles['status-container'])}>
					<StoreStatus />
					<PrinterStatus />
				</div>
				<InputScan />
			</div>
			<InputAmount
				keyboardControlled
				onChange={(newQty) => {
					setParcelCount(newQty);
				}}
			/>
		</div>
	);
}

export default BarBottomShipping;
