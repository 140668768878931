import BarActions, { BarActionsProps } from '../BarActions/BarActions';
import Logger from '../Logger/Logger';
import SettingsBar from '../SettingsBar/SettingsBar';
import styles from './Sidebar.module.scss';

type Props = BarActionsProps;

function Sidebar(props: Props) {
	const { actions } = props;

	return (
		<div className={styles.container}>
			<SettingsBar />
			<BarActions actions={actions} />
			<Logger />
		</div>
	);
}

export default Sidebar;
