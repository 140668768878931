import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';

import { PrinterApi } from '../classes/PrinterApi';
import { LoggerContext, LogLevel } from './LoggerContext';

function getConfig() {
	return {
		printHost: localStorage.getItem('printHost') || 'http://localhost',
		printPort: parseInt(localStorage.getItem('printPort') || '3333'),
		printer: localStorage.getItem('printer') || '',
	};
}

export enum PrintServerConnection {
	'disconnected' = 'disconnected',
	'connected' = 'connected',
	'versionError' = 'versionError',
}

export interface PrinterConfig {
	printHost: string;
	printPort: number;
}

interface PrinterState {
	hasPrintServerConnection?: PrintServerConnection;
	printers: string[];
	printer?: string;
	config: PrinterConfig;
	setConfig?: React.Dispatch<React.SetStateAction<PrinterConfig>>;
	getConfig: () => PrinterConfig;
	setPrinter: (printer: string) => void;
}

const defaultPrinterState: PrinterState = {
	printers: [],
	config: getConfig(),
	getConfig,
	setPrinter: () => {
		console.error('Context not initialized');
	},
};
export const PrinterContext = createContext(defaultPrinterState);

function PrinterContextProvider(props: PropsWithChildren) {
	const { children } = props;

	const [config, setConfig] = useState<PrinterConfig>(getConfig());
	const [printers, setPrinters] = useState<string[]>([]);
	const [printer, setPrinter] = useState<string>();
	const [hasPrintServerConnection, setPrintServerConnection] = useState<
		PrintServerConnection | undefined
	>();
	const [nudgedForUpdate, setNudgedForUpdate] = useState(false);
	const { addLog } = useContext(LoggerContext);

	const testConnection = useCallback(() => {
		setTimeout(() => {
			const printerApi = new PrinterApi(config);

			printerApi
				.ping()
				.then((hasConnection) => {
					if (!hasConnection) {
						setPrintServerConnection(
							PrintServerConnection.disconnected,
						);

						return;
					}

					printerApi.getPrinters().then((printers) => {
						setPrintServerConnection(
							PrintServerConnection.connected,
						);
						setPrinters(printers);
					});

					printerApi.getPrinter().then((serverPrinter) => {
						if (!printer) setPrinter(serverPrinter?.printer);
					});

					if (!nudgedForUpdate) {
						printerApi.versionOkay().then((versionOkay) => {
							if (!versionOkay) {
								addLog(
									'APP',
									'There seems to be an update for the printserver',
									LogLevel.bright,
								);
								setNudgedForUpdate(true);

								return;
							}
						});
					}
				})
				.catch(() => {
					setPrintServerConnection(
						PrintServerConnection.disconnected,
					);
				});
		}, 2000);

		// NOTE: Not sure what I want with these deps
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		testConnection();
		const interval = setInterval(testConnection, 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [testConnection]);

	useEffect(() => {
		if (hasPrintServerConnection !== PrintServerConnection.connected) {
			return;
		}
		const printerApi = new PrinterApi(config);
		printerApi.setPrinter(printer);
	}, [printer, config, hasPrintServerConnection]);

	const context: PrinterState = {
		...defaultPrinterState,
		hasPrintServerConnection,
		setConfig,
		config,
		printers,
		setPrinter,
		printer,
	};

	return (
		<PrinterContext.Provider value={context}>
			{children}
		</PrinterContext.Provider>
	);
}

export default PrinterContextProvider;
