import {
	ChangeEvent,
	FormEvent,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { FaPlay } from 'react-icons/fa';

import { ActionContext } from '../../contexts/ActionContext';
import { AppContext } from '../../contexts/AppContext';
import { LoggerContext, LogLevel } from '../../contexts/LoggerContext';
import Button from '../Button/Button';
import styles from './InputScan.module.scss';

const doNotFocusIfElement = ['input'];

function InputScan() {
	const inputRef = useRef<HTMLInputElement>(null);
	const [value, setValue] = useState('');
	const { action, handler, context } = useContext(ActionContext);
	const { addLog } = useContext(LoggerContext);
	const { processing, setProcessing, togglePowerMode } =
		useContext(AppContext);

	function onKeydown(event: KeyboardEvent) {
		const { key, metaKey, ctrlKey } = event;

		const currentElement =
			(document.activeElement &&
				document?.activeElement.tagName.toLowerCase()) ??
			'';

		if (
			doNotFocusIfElement.includes(currentElement) ||
			(metaKey && key !== 'v') ||
			(ctrlKey && key !== 'v')
		) {
			return;
		}

		inputRef.current?.focus();
	}

	function onChange(event: ChangeEvent<HTMLInputElement>) {
		setValue(event.target.value);
	}

	useEffect(() => {
		document.addEventListener('keydown', onKeydown);

		return () => {
			document.removeEventListener('keydown', onKeydown);
		};
	}, []);

	return (
		<form
			className={styles.container}
			onSubmit={async (e: FormEvent<HTMLFormElement>) => {
				e.preventDefault();

				if (value === '42069') {
					togglePowerMode(value);
					setValue('');

					return;
				}

				if (!handler || typeof handler !== 'function') {
					addLog('APP', 'No action selected!', LogLevel.warning);

					return;
				}

				setProcessing(true);
				await handler(value, context).catch((error) => {
					console.error(error);
					addLog(
						'APP',
						`Something went wrong processing [${action}]`,
						LogLevel.bright,
					);
					setProcessing(false);
				});
				setValue('');
				setProcessing(false);
			}}
		>
			<input
				type='text'
				className={styles.input}
				ref={inputRef}
				value={value}
				onChange={onChange}
				disabled={processing}
			/>
			<Button className={styles.button} disabled={processing}>
				<FaPlay />
			</Button>
		</form>
	);
}

export default InputScan;
