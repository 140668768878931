import { useCallback, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { RiQrScan2Line } from 'react-icons/ri';
import { OnResultFunction, QrReader } from 'react-qr-reader';
import { Formik } from 'formik';

import { PrinterApi } from '../../classes/PrinterApi';
import { ActionContext } from '../../contexts/ActionContext';
import { MageContext } from '../../contexts/MageContext';
import { PrinterContext } from '../../contexts/PrinterContext';
import { WarehouseContext } from '../../contexts/WarehouseContext';
import Button from '../Button/Button';
import Card from '../Card/Card';
import QRLabelGenerator from '../QRLabelGenerator/QRLabelGenerator';
import WarehouseProduct from '../WarehouseProduct/WarehouseProduct';
import styles from './MainWarehouseWindow.module.scss';

function MainWarehouseWindow() {
	const { setConfig } = useContext(MageContext);
	const { getConfig: getPrinterConfig } = useContext(PrinterContext);
	const { product, setProduct } = useContext(WarehouseContext);
	const { action, handler, context } = useContext(ActionContext);

	const printApi = new PrinterApi(getPrinterConfig());

	const [isScanning, setIsScanning] = useState(false);

	const handleScan = useCallback(
		async (sku: string) => {
			if (handler) {
				await handler(sku, context);

				return true;
			}
		},
		[handler, context],
	);

	const onScan: OnResultFunction = useCallback(
		(result) => {
			result && setIsScanning(false);

			const resultText = result?.getText();
			if (!resultText || product) {
				return;
			}

			if (resultText.includes('[config]:')) {
				const configString = resultText?.replace('[config]:', '');
				setConfig &&
					setConfig((prev) => ({
						...prev,
						...JSON.parse(configString),
					}));

				toast.success(
					'Loaded config from QR. Use the config page to SAVE the new settings.',
				);

				return;
			}

			handleScan(resultText);

			return;
		},
		[product, setConfig, handleScan],
	);

	return (
		<Card>
			{action == 'qr_generate' && (
				<QRLabelGenerator
					// showOutput={process.env.NODE_ENV === 'development'}
					dpi={10}
					onRender={(base64, sku) => {
						printApi.print(base64, sku, 'png');
					}}
				/>
			)}

			{(action == 'inventory_manage' || action == 'inventory_minusone') &&
				!isScanning && (
					<div className={styles.container}>
						<div className={styles.innerContainer}>
							<Button
								onClick={() => {
									setIsScanning(true);
									setProduct(null);
								}}
							>
								<RiQrScan2Line style={{ fontSize: '3em' }} />
							</Button>
						</div>
						<Formik
							initialValues={{ sku: '' }}
							onSubmit={async (values, formikHelpers) => {
								setProduct(null);
								await handleScan(values.sku);

								formikHelpers.setSubmitting(false);
								formikHelpers.resetForm();
							}}
						>
							{({
								handleSubmit,
								isSubmitting,
								values,
								handleChange,
							}) => (
								<form onSubmit={handleSubmit}>
									<div
										style={{
											display: 'flex',
										}}
									>
										<input
											name='sku'
											value={values.sku}
											onChange={handleChange}
										/>
										<Button
											onClick={handleSubmit}
											disabled={isSubmitting}
										>
											Zoeken
										</Button>
									</div>
								</form>
							)}
						</Formik>
					</div>
				)}
			{(action == 'inventory_manage' || action == 'inventory_minusone') &&
				isScanning && (
					<QrReader
						constraints={{ facingMode: 'environment' }}
						scanDelay={1000}
						videoContainerStyle={{ width: '100%' }}
						onResult={onScan}
						videoId='QR'
					/>
				)}
			{product && (
				<WarehouseProduct
					product={product}
					hideForm={action === 'inventory_minusone'}
				/>
			)}
			{product === false && (
				<Card type='error'>{"This product doesn't seem to exist"}</Card>
			)}
		</Card>
	);
}

export default MainWarehouseWindow;
