import { useField } from 'formik';

import styles from '../FormInput/FormInput.module.scss';

interface Props {
	label: string;
	name: string;
	defaultValue?: string;
	options: string[];
	noOptions?: string;
}

function FormSelect(props: Props) {
	const { label, name, options, noOptions, ...rest } = props;

	const [field, _meta, helpers] = useField(name);

	return (
		<label className={styles.container}>
			<span className={styles.label}>{label}</span>

			<select
				className={styles.select}
				{...rest}
				{...field}
				name={name}
				onChange={(event) => {
					const value = event.target.value;
					helpers.setValue(value);
				}}
				value={field.value}
			>
				{!options.length ? (
					<option value='null'>{noOptions}</option>
				) : (
					options.map((option) => {
						return (
							<option value={option} key={option}>
								{option}
							</option>
						);
					})
				)}
			</select>
		</label>
	);
}

export default FormSelect;
