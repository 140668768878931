export interface WarehouseProduct {
	sku: string;
	name: string;
	width: string;
	height: string;
	shape: 'Round' | 'Square' | 'Rectangle' | 'Circle' | 'Special';
	isCustom: boolean;
}

export interface WarehouseStockProduct extends WarehouseProduct {
	isInStock: boolean;
	qty: number;
	isManageStock: boolean;
	notifyQty: number;
	isCustom: false;
}

export const getShape = (shape: string) => {
	switch (shape) {
		case 'Round':
			return '●';
		case 'Square':
			return '■';
		case 'Rectangle':
			return '▬';
		case 'Oval':
			return '⬬';
		case 'Special':
			return '★';
		default:
			return '';
	}
};
