import { FaExpand } from 'react-icons/fa';

import Button from '../Button/Button';

function ButtonFullScreen() {
	return (
		<Button
			onClick={() => {
				if (!document.fullscreenElement) {
					document.documentElement.requestFullscreen();
				} else {
					if (document.exitFullscreen) {
						document.exitFullscreen();
					}
				}
			}}
		>
			<FaExpand />
		</Button>
	);
}

export default ButtonFullScreen;
