import cx from 'classnames';

import PrinterStatus from '../PrinterStatus/PrinterStatus';
import StoreStatus from '../StoreStatus/StoreStatus';
import styles from './BarBottom.module.scss';

function BarBottomWarehouse() {
	return (
		<div className={cx(styles.container)}>
			<div className={cx(styles['sub-container'])}>
				<div className={cx(styles['status-container'])}>
					<StoreStatus />
					<PrinterStatus />
				</div>
				{/* <InputScan /> */}
			</div>
			{/* <InputAmount /> */}
		</div>
	);
}

export default BarBottomWarehouse;
