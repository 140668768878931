import { PrinterConfig } from '../contexts/PrinterContext';

const requiredVersion = '0.3.0';

export class PrinterApi {
	private baseUrl: string;

	constructor(config: PrinterConfig) {
		const { printHost, printPort } = config;

		this.baseUrl = `${printHost}:${printPort}`;
	}

	public async ping() {
		const pingData = await this._call('/ping');

		return pingData?.connection;
	}

	public async getVersion() {
		const versionData = await this._call('/version');

		return versionData?.version;
	}

	public async versionOkay() {
		const version = await this.getVersion();

		if (version !== requiredVersion) {
			return false;
		}

		return true;
	}

	public async getPrinter() {
		return await this._call('/printer');
	}

	public async getPrinters() {
		return await this._call('/printers');
	}

	public async setPrinter(newPrinter?: string) {
		const updated = await this._call('/printer', {
			printer: newPrinter,
		});

		return !!updated?.printer;
	}

	public async print(data: string, code: string, type = 'pdf') {
		const printState = await this._call('/print', {
			[type]: data,
		});

		if (!printState || (printState && printState.print !== 'ok')) {
			download(`label-${code}.${type}`, data);

			return false;
		}

		return true;
	}

	public async getCacheStatus() {
		return await this._call('/cache');
	}

	public async clearCache() {
		return await this._call('/cacheClear');
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private async _call(url: string, payload?: any) {
		const apiUrl = `${this.baseUrl}${url}`;

		const requestOptions: RequestInit = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		};

		if (payload) {
			requestOptions.method = 'POST';
			requestOptions.body = JSON.stringify(payload);
		}

		const data = await fetch(apiUrl, requestOptions)
			.then((response) => response.json())
			.catch((err) => {
				console.warn(err);
			});

		return data;
	}
}

function download(filename: string, text: string) {
	const element = document.createElement('a');
	element.setAttribute(
		'href',
		`data:application/pdf;base64,${encodeURIComponent(text)}`,
	);
	element.setAttribute('download', filename);

	element.style.display = 'none';
	document.body.appendChild(element);

	element.click();

	document.body.removeChild(element);
}
