import { useCallback, useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { FaStore, FaTruckLoading } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';

import { MageContext } from '../../contexts/MageContext';
import ConnnectionStatus from '../ConnectionStatus/ConnectionStatus';

function StoreStatus() {
	const { hasStoreConnection, config } = useContext(MageContext);
	const { siteUrl } = config;

	const notifyStatus = useCallback(() => {
		const toastOpt = { id: 'mageStatus', duration: 5000 };

		hasStoreConnection === undefined
			? toast('Checking Magento status...', {
					...toastOpt,
					duration: Infinity,
			  })
			: hasStoreConnection
			? toast.success(`Magento connected using ${siteUrl}`, {
					...toastOpt,
					duration: 1500,
			  })
			: toast.error(`Could not connect to Magento`, toastOpt);
	}, [hasStoreConnection, siteUrl]);

	useEffect(() => {
		notifyStatus();
	}, [notifyStatus]);

	return (
		<ConnnectionStatus
			renderIcon={() =>
				hasStoreConnection === undefined ? (
					<FaTruckLoading color='blue' />
				) : hasStoreConnection ? (
					<FaStore color='green' />
				) : (
					<FaXmark color='red' />
				)
			}
			notifyStatus={notifyStatus}
		/>
	);
}

export default StoreStatus;
