import { ReactNode } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

import { MageAddress } from '../../classes/Magento.types';
import DecoratedText from '../DecoratedText/DecoratedText';
import styles from './Address.module.scss';

interface Props {
	address: MageAddress;
	Label?: ReactNode;
	method?: string;
}

function Address(props: Props) {
	const { address, Label, method } = props;
	const {
		company = '[PRIVATE]',
		firstname,
		lastname,
		street,
		postcode,
		city,
		country,
		email,
		phone,
	} = address;

	return (
		<div className={styles.container}>
			{Label && <div className={styles.label}>{Label}</div>}
			<div className={styles.wrapper}>
				<div className={styles.company}>{company}</div>
				<div>
					{firstname} {lastname}
				</div>
				<div>{street}</div>
				<div>
					{postcode} {city}{' '}
				</div>
				<ReactCountryFlag countryCode={country} svg />
			</div>
			<DecoratedText Icon={FaEnvelope}>{email}</DecoratedText>
			<DecoratedText Icon={FaPhone}>{phone}</DecoratedText>
			{method && (
				<div className={styles.shippingMethod}>
					{method.split('_')[0]}
				</div>
			)}
		</div>
	);
}

export default Address;
