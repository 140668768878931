import { useContext, useEffect } from 'react';
import { IconType } from 'react-icons';
import cx from 'classnames';

import {
	ActionContext,
	OrderActionHandler,
} from '../../contexts/ActionContext';
import { AppContext } from '../../contexts/AppContext';
import Button from '../Button/Button';
import styles from './Action.module.scss';

interface Props {
	icon: IconType;
	actionId: string;
	label: string;
	group: string;
	isPowerMode?: boolean;
	handler?: OrderActionHandler;
}
export type ActionProps = Props;

function Action(props: Props) {
	const { actionId, label, isPowerMode, icon: Icon, handler } = props;

	const { action, set, unset } = useContext(ActionContext);
	const { powerMode } = useContext(AppContext);

	useEffect(() => {
		action === actionId && set(actionId, handler);
		// NOTE: Yes only on the handler
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handler]);

	useEffect(() => {
		action === actionId && isPowerMode && unset();
		// NOTE: Yes only on powerMode
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [powerMode]);

	return (
		<Button
			className={cx(styles.container, {
				[styles.selected]: action === actionId,
				[styles.powermode]: powerMode,
				[styles.noHandler]: !handler || typeof handler !== 'function',
			})}
			onClick={() => {
				set(actionId, handler);
			}}
		>
			<Icon />
			<span>{label}</span>
		</Button>
	);
}

export default Action;
