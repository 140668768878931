import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useCallback,
	useEffect,
	useState,
} from 'react';

import { MagentoApi } from '../classes/MagentoApi';

function getConfig() {
	return {
		siteUrl:
			localStorage.getItem('siteUrl') || 'https://www.emaillegigant.nl',
		adminBase: localStorage.getItem('adminBase') || 'backoffice',
		restBase: localStorage.getItem('restBase') || 'rest/V1',
		accessToken: localStorage.getItem('accessToken') || undefined,
	};
}

export interface MageConfig {
	siteUrl: string;
	adminBase: string;
	restBase: string;
	accessToken?: string;
}
interface MageState {
	hasStoreConnection?: boolean;
	config: MageConfig;
	setConfig?: Dispatch<SetStateAction<MageConfig>>;
	getConfig: () => MageConfig;
}

const defaultMageState: MageState = {
	config: getConfig(),
	getConfig,
};
export const MageContext = createContext(defaultMageState);

function MageContextProvider(props: PropsWithChildren) {
	const { children } = props;

	const [config, setConfig] = useState<MageConfig>(getConfig());
	const [hasStoreConnection, setStoreConnection] = useState<
		boolean | undefined
	>(undefined);

	const testConnection = useCallback(() => {
		const mageApi = new MagentoApi(config);
		mageApi.checkConnection().then((hasConnection) => {
			setStoreConnection(hasConnection);
		});
	}, [config]);

	useEffect(() => {
		testConnection();
		const interval = setInterval(testConnection, 60 * 1000);

		return () => {
			clearInterval(interval);
		};
	}, [testConnection]);

	const context: MageState = {
		...defaultMageState,
		hasStoreConnection,
		config,
		setConfig,
		getConfig,
	};

	return (
		<MageContext.Provider value={context}>{children}</MageContext.Provider>
	);
}

export default MageContextProvider;
