import { useContext, useState } from 'react';
import { FaCheck, FaX } from 'react-icons/fa6';
import { Formik } from 'formik';

import { MagentoWarehouseApi } from '../../classes/MagentoWarehouseApi';
import { WarehouseStockProduct } from '../../classes/Warehouse.utils';
import { LoggerContext, LogLevel } from '../../contexts/LoggerContext';
import { MageContext } from '../../contexts/MageContext';
import { WarehouseContext } from '../../contexts/WarehouseContext';
import Button from '../Button/Button';
import Card from '../Card/Card';
import InputAmount from '../InputAmount/InputAmount';
import ProductImage from '../ProductImage/ProductImage';
import ProductSize from '../ProductSize/ProductSize';
import styles from './WarehouseProduct.module.scss';

interface Props {
	product: WarehouseStockProduct;
	hideForm?: boolean;
}

function WarehouseProduct(props: Props) {
	const { product, hideForm } = props;
	const {
		sku,
		name,
		width,
		height,
		shape,
		isInStock,
		qty,
		isManageStock,
		notifyQty,
		isCustom,
	} = product;

	const [newQty, setNewQty] = useState(qty ?? 1);

	const { setProduct } = useContext(WarehouseContext);
	const { getConfig: getMageConfig, config } = useContext(MageContext);
	const { siteUrl, adminBase } = config;
	const { addLog } = useContext(LoggerContext);
	const mageApi = new MagentoWarehouseApi(getMageConfig());

	const formVisible = !hideForm && isManageStock && !isCustom;

	return (
		<div className={styles.container}>
			<div className={styles['product-info-container']}>
				<div className={styles.productImage}>
					<ProductImage sku={sku} width='150px' />
				</div>
				<div>
					<div className={styles['product-name']}>{name}</div>
					<ProductSize
						width={width}
						height={height}
						shape={shape}
						className={styles['product-size']}
					/>
					<div className={styles['product-info']}>
						<div>Non-custom product:</div>
						<div>
							{!isCustom ? (
								<FaCheck color='green' />
							) : (
								<FaX color='red' />
							)}
						</div>
						<div>Stock managed:</div>
						<div>
							{isManageStock ? (
								<FaCheck color='green' />
							) : (
								<FaX color='red' />
							)}
						</div>
						<div>In stock:</div>
						<div>
							{isInStock ? (
								<FaCheck color='green' />
							) : (
								<FaX color='red' />
							)}
						</div>
						<div>min QTY:</div>
						<div>{notifyQty}</div>
						<div>QTY:</div>
						<div>{qty}</div>
					</div>
				</div>
			</div>
			{formVisible && (
				<Formik
					initialValues={{ qty }}
					onSubmit={(_values, _formikHelpers) => {
						// TODO: .
						throw new Error('Function not implemented.');
					}}
				>
					<div className={styles.formWrapper}>
						<InputAmount
							defaultValue={qty}
							onChange={(newQty) => {
								setNewQty(newQty);
							}}
						/>
						<Button
							onClick={async () => {
								const data = {
									qty: newQty,
								};

								const response = await mageApi.updateProduct(
									sku,
									data,
								);
								for (const resp of response) {
									addLog(
										'WARE',
										resp,
										resp.includes(sku)
											? LogLevel.bright
											: LogLevel.normal,
									);
								}

								setProduct(null);
							}}
						>
							Update
						</Button>
					</div>
				</Formik>
			)}
			{!isManageStock && (
				<Card type='error'>
					Stock management for this product has been disabled.{' '}
					<a
						href={`${siteUrl}/${adminBase}/catalog/product/edit/id/6786/${sku}`}
						target={'_blank'}
						rel='noopener noreferrer'
					>
						Configure the stock options
					</a>{' '}
					for this product
				</Card>
			)}
		</div>
	);
}

export default WarehouseProduct;
