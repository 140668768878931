import {
	createContext,
	PropsWithChildren,
	useCallback,
	useContext,
	useState,
} from 'react';

import { MageOrder } from '../classes/Magento.types';
import { MagentoOrderApi } from '../classes/MagentoOrderApi';
import { MagentoWarehouseApi } from '../classes/MagentoWarehouseApi';
import { PrinterApi } from '../classes/PrinterApi';
import { WarehouseStockProduct } from '../classes/Warehouse.utils';
import { LoggerContext, LogLevel } from './LoggerContext';
import { MageContext } from './MageContext';
import { MageOrderContext } from './MageOrderContext';
import { PrinterContext } from './PrinterContext';
import { WarehouseContext } from './WarehouseContext';

export interface HandlerContext {
	printerApi: PrinterApi;
	mageOrderApi: MagentoOrderApi;
	mageWarehouseApi: MagentoWarehouseApi;
	addLog: (type: string, newLog: string, logLevel?: LogLevel) => void;
	setIsLoading: (isLoading: boolean) => void;
	setMageOrder: (order: MageOrder | null, isExport?: boolean) => void;
	parcelCount: number;
	setParcelCount: (number: number) => void;
	setProduct: (product: WarehouseStockProduct | false | null) => void;
}

export type OrderActionHandler = (
	orderId: string,
	context: HandlerContext,
) => Promise<boolean>;
interface ActionState {
	action: string;
	handler: OrderActionHandler;
	set: (newAction: string, newHandler?: OrderActionHandler) => void;
	unset: () => void;
	context: HandlerContext;
}
const baseHandler: OrderActionHandler = async () => {
	return true;
};

const defaultActionState: ActionState = {
	action: '',
	handler: baseHandler,
	set: (_newActionId, _newHandler) => {
		console.error('Context not initialized');
	},
	unset: () => undefined,
	context: {} as HandlerContext,
};

export const ActionContext = createContext(defaultActionState);

function ActionContextProvider(props: PropsWithChildren) {
	const { children } = props;

	const { config: mageConfig } = useContext(MageContext);
	const { config: printConfig } = useContext(PrinterContext);
	const { addLog } = useContext(LoggerContext);
	const { setOrder, parcelCount, setParcelCount, setIsLoading } =
		useContext(MageOrderContext);
	const { setProduct } = useContext(WarehouseContext);

	const [action, setAction] = useState<string>('');
	const [handler, setHandler] = useState<OrderActionHandler>(baseHandler);

	const set = (newAction: string, newHandler?: OrderActionHandler) => {
		setAction(newAction);
		setHandler(() => newHandler ?? baseHandler);
	};

	const unset = () => {
		setAction('');
		setHandler(baseHandler);
	};

	const handlerContext: () => HandlerContext = useCallback(
		() => ({
			printerApi: new PrinterApi(printConfig),
			mageOrderApi: new MagentoOrderApi(mageConfig),
			mageWarehouseApi: new MagentoWarehouseApi(mageConfig),
			addLog,
			setMageOrder: setOrder,
			setIsLoading,
			parcelCount,
			setParcelCount,
			setProduct,
		}),
		[
			addLog,
			mageConfig,
			printConfig,
			setOrder,
			setIsLoading,
			parcelCount,
			setParcelCount,
			setProduct,
		],
	);

	const context: ActionState = {
		action,
		handler,
		set,
		unset,
		context: handlerContext(),
	};

	return (
		<ActionContext.Provider value={context}>
			{children}
		</ActionContext.Provider>
	);
}

export default ActionContextProvider;
