import { useContext, useState } from 'react';
import cx from 'classnames';

import { ModalContext } from '../../contexts/ModalContext';
import Button from '../Button/Button';
import ConfigViewPrinter from '../ConfigViewPrinter/ConfigViewPrinter';
import ConfigViewStore from '../ConfigViewStore/ConfigViewStore';
import styles from './ConfigView.module.scss';

enum ConfigPages {
	Printer,
	Store,
}

interface PageData {
	key: ConfigPages;
	title: string;
	view: JSX.Element;
}

const pages: PageData[] = [
	{
		key: ConfigPages.Store,
		title: 'Store',
		view: <ConfigViewStore />,
	},
	{
		key: ConfigPages.Printer,
		title: 'Printer',
		view: <ConfigViewPrinter />,
	},
];

function ConfigView() {
	const [view, setView] = useState(pages[0].key);
	const { toggleVisible } = useContext(ModalContext);

	return (
		<div className={styles.container}>
			<div className={styles.menu}>
				<ul className={styles.list}>
					{pages.map((page) => (
						<li
							key={page.key}
							onClick={() => {
								setView(page.key);
							}}
							className={cx(styles.listItem, {
								[styles.selected]: page.key === view,
							})}
						>
							{page.title}
						</li>
					))}
				</ul>
				<Button onClick={() => toggleVisible()}>Close</Button>
			</div>
			<div className={styles.page}>
				{pages.find((page) => view === page.key)?.view ?? (
					<span>No View</span>
				)}
			</div>
		</div>
	);
}

export default ConfigView;
