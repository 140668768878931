import { PropsWithChildren } from 'react';
import { IconType } from 'react-icons';

import styles from './DecoratedText.module.scss';

interface Props extends PropsWithChildren {
	Icon: IconType;
}

function DecoratedText(props: Props) {
	const { Icon, children } = props;

	if (!children) return null;

	return (
		<div className={styles.container}>
			<Icon className={styles.icon} /> {children}
		</div>
	);
}

export default DecoratedText;
