import Card from '../Card/Card';

interface Props {
	requiresStore?: boolean;
	requiresPrinter?: boolean;
	componentName?: string;
}

function SomethingWentWrong(props: Props) {
	const {
		requiresStore,
		requiresPrinter,
		componentName = 'this component',
	} = props;
	const requiredServices = [];

	if (requiresStore) requiredServices.push('Magento Store');
	if (requiresPrinter) requiredServices.push('Printer Service');

	return (
		<Card type='error'>
			Could not load {componentName}.{' '}
			{requiredServices.length &&
				'It needs to be connected to the following services:'}{' '}
			{requiredServices.join(', ')}
		</Card>
	);
}

export default SomethingWentWrong;
