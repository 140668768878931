import { useContext } from 'react';
import { FaBoxes, FaWarehouse } from 'react-icons/fa';

import { AppContext } from '../../contexts/AppContext';
import Button from '../Button/Button';

function ButtonAppMode() {
	const { appMode, setAppMode } = useContext(AppContext);

	return (
		<Button
			onClick={() => {
				setAppMode((prev) => {
					if (prev === 'shipping') {
						return 'warehouse';
					}

					return 'shipping';
				});
			}}
		>
			{appMode === 'shipping' && <FaBoxes />}
			{appMode === 'warehouse' && <FaWarehouse />}
		</Button>
	);
}

export default ButtonAppMode;
