import { useContext, useEffect, useRef } from 'react';
import cx from 'classnames';

import { LoggerContext } from '../../contexts/LoggerContext';
import styles from './Logger.module.scss';

function Logger() {
	const { logs } = useContext(LoggerContext);

	const windowRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!windowRef.current) return;

		windowRef.current.scrollTop = windowRef.current?.scrollHeight
			? windowRef.current?.scrollHeight
			: 0;
	}, [logs]);

	return (
		<div ref={windowRef} className={cx(styles.container)}>
			{logs.map((log, i) => {
				const { type, message, level } = log;

				return (
					<span
						key={i}
						dangerouslySetInnerHTML={{
							__html: `[${type}] ${message}`,
						}}
						style={{
							color: level,
						}}
					/>
				);
			})}
		</div>
	);
}

export default Logger;
