import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react';
import toast from 'react-hot-toast';

import { LoggerContext } from './LoggerContext';

type AppModes = 'shipping' | 'warehouse';

const noOp = () => {
	console.error('Context not initialized');
};

interface AppState {
	processing: boolean;
	setProcessing: Dispatch<SetStateAction<boolean>>;
	togglePowerMode: (key: string) => void;
	powerMode: boolean;
	appMode: AppModes;
	setAppMode: Dispatch<SetStateAction<AppModes>>;
}

const defaultAppState: AppState = {
	processing: false,
	powerMode: false,
	appMode: (localStorage.getItem('appMode') as AppModes) ?? 'shipping',
	setAppMode: noOp,
	togglePowerMode: (_key: string) => undefined,
	setProcessing: (_value) => undefined,
};
export const AppContext = createContext(defaultAppState);

function AppContextProvider(props: PropsWithChildren) {
	const { children } = props;

	const { addLog } = useContext(LoggerContext);
	const [powerMode, setPowerMode] = useState<boolean>(
		defaultAppState.powerMode,
	);
	const [appMode, setAppMode] = useState<AppModes>(defaultAppState.appMode);
	const [processing, setProcessing] = useState<boolean>(
		defaultAppState.processing,
	);

	useEffect(() => {
		localStorage.setItem('appMode', appMode);
	}, [appMode]);

	const togglePowerMode = useCallback(
		(key: string) => {
			if (key != '42069') {
				return;
			}

			setPowerMode((prev) => {
				addLog('APP', `Powermode ${!prev ? 'enabled' : 'disabled'}`);

				return !prev;
			});
		},
		[addLog],
	);

	useEffect(() => {
		toast.success(`Set app mode to ${appMode}`, { id: 'appMode' });
	}, [appMode]);

	const context: AppState = {
		...defaultAppState,
		appMode,
		setAppMode,
		processing,
		setProcessing,
		powerMode,
		togglePowerMode,
	};

	return (
		<AppContext.Provider value={context}>{children}</AppContext.Provider>
	);
}

export default AppContextProvider;
