import cx from 'classnames';

import styles from './App.module.scss';
import AppManager from './AppManager';
import ConfigView from './components/ConfigView/ConfigView';
import Modal from './components/Modal/Modal';
import Contexts from './contexts/Contexts';

function App() {
	return (
		<Contexts>
			<main
				className={cx(styles.container, {
					[styles.development]:
						process.env.NODE_ENV === 'development',
				})}
			>
				<AppManager />
				<Modal>
					<ConfigView />
				</Modal>
			</main>
		</Contexts>
	);
}

export default App;
