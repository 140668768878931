import {
	createContext,
	Dispatch,
	PropsWithChildren,
	SetStateAction,
	useState,
} from 'react';

import { WarehouseStockProduct } from '../classes/Warehouse.utils';

interface WarehouseContext {
	product: WarehouseStockProduct | false | null;
	setProduct: Dispatch<SetStateAction<WarehouseStockProduct | false | null>>;
}

const defaultWarehouseState: WarehouseContext = {
	product: null,
	setProduct: () => undefined,
};

export const WarehouseContext = createContext<WarehouseContext>(
	defaultWarehouseState,
);

function WarehouseContextProvider(props: PropsWithChildren) {
	const { children } = props;
	const [product, setProduct] = useState<
		WarehouseStockProduct | false | null
	>(null);

	const context: WarehouseContext = {
		...defaultWarehouseState,
		product,
		setProduct,
	};

	return (
		<WarehouseContext.Provider value={context}>
			{children}
		</WarehouseContext.Provider>
	);
}

export default WarehouseContextProvider;
