import { useContext } from 'react';

import { MageContext } from '../../contexts/MageContext';
import Modal from '../Modal/Modal';
import styles from './ProductImage.module.scss';

interface Props {
	sku: string;
	width?: string;
	orderId?: number;
	itemId?: number;
}

function ProductImage(props: Props) {
	const { sku, width = '200px', orderId, itemId } = props;

	const { config } = useContext(MageContext);
	const { siteUrl, restBase } = config;

	if (!sku || sku.length > 10) {
		return null;
	}

	return (
		<div className={styles.container}>
			<img
				className={styles.image}
				src={`${siteUrl}/${restBase}/shippr/product/${sku}/image/${orderId}/${itemId}`}
				alt=''
				style={{ width }}
			/>
			<Modal>
				<img
					className={styles.image}
					src={`${siteUrl}/${restBase}/shippr/product/${sku}/image/${orderId}/${itemId}`}
					alt=''
					style={{ width }}
				/>
			</Modal>
		</div>
	);
}

export default ProductImage;
