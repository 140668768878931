import { PropsWithChildren } from 'react';
import { Toaster } from 'react-hot-toast';

import ActionContextProvider from './ActionContext';
import AppContextProvider from './AppContext';
import LoggerContextProvider from './LoggerContext';
import MageContextProvider from './MageContext';
import MageOrderContextProvider from './MageOrderContext';
import ModalContextProvider from './ModalContext';
import PrinterContextProvider from './PrinterContext';
import WarehouseContextProvider from './WarehouseContext';

function Contexts(props: PropsWithChildren) {
	const { children } = props;

	return (
		<LoggerContextProvider>
			<AppContextProvider>
				<MageOrderContextProvider>
					<WarehouseContextProvider>
						<ActionContextProvider>
							<MageContextProvider>
								<PrinterContextProvider>
									<ModalContextProvider>
										{children}
										<Toaster
											position='top-left'
											toastOptions={{ duration: 5000 }}
										/>
									</ModalContextProvider>
								</PrinterContextProvider>
							</MageContextProvider>
						</ActionContextProvider>
					</WarehouseContextProvider>
				</MageOrderContextProvider>
			</AppContextProvider>
		</LoggerContextProvider>
	);
}

export default Contexts;
