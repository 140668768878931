import { useFormikContext } from 'formik';

import {
	calculateOrderItems,
	exportDescriptionType,
} from '../../classes/Constants';
import { MageOrder } from '../../classes/Magento.types';
import Button from '../Button/Button';
import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';
import { ExportFormData } from '../OrderDisplay/OrderDisplay';
import styles from './OrderDisplayExportForm.module.scss';

interface Props {
	order: MageOrder;
}

function OrderDisplayExportForm(props: Props) {
	const { order } = props;

	const { isSubmitting, errors, values } = useFormikContext<ExportFormData>();

	return (
		<div className={styles.container}>
			<FormSelect
				name='description'
				label='Description'
				options={exportDescriptionType}
			/>
			<FormInput label='Invoice ID' name='invoice' />
			<FormInput label='Classification' name='classification' />
			<ul>
				<li>
					<>
						{order.currency}{' '}
						{calculateOrderItems(
							Object.values(values.items),
							'value',
						)}{' '}
					</>
				</li>
				<li>
					<>
						Weight:{' '}
						{calculateOrderItems(
							Object.values(values.items),
							'weight',
						)}{' '}
						{'grams'}
					</>
				</li>
			</ul>
			{errors.itemWeights && (
				<div className={styles.error}>{errors.itemWeights}</div>
			)}
			<Button type='submit' disabled={isSubmitting}>
				Submit
			</Button>
		</div>
	);
}

export default OrderDisplayExportForm;
