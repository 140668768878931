import { PropsWithChildren, useContext } from 'react';

import { ModalContext } from '../../contexts/ModalContext';
import styles from './Modal.module.scss';

interface Props extends PropsWithChildren {
	handleClose?: () => void;
}

function Modal(props: Props) {
	const { children } = props;

	const { visible, toggleVisible } = useContext(ModalContext);

	if (!visible) {
		return null;
	}

	return (
		<div className={styles.overlay} onClick={toggleVisible}>
			<div
				className={styles.container}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{children}
			</div>
		</div>
	);
}

export default Modal;
