import { useContext } from 'react';

import { AppContext } from './contexts/AppContext';
import ShippingApp from './ShippingApp';
import WarehouseApp from './WarehouseApp';

function AppManager() {
	const { appMode } = useContext(AppContext);

	switch (appMode) {
		case 'shipping':
			return <ShippingApp />;
		case 'warehouse':
			return <WarehouseApp />;
		default:
			return <span>Unknown appMode selected</span>;
	}
}

export default AppManager;
