import { useContext } from 'react';
import cx from 'classnames';

import { MageOrderContext } from '../../contexts/MageOrderContext';
import Card from '../Card/Card';
import OrderDisplay from '../OrderDisplay/OrderDisplay';
import styles from './MainShippingWindow.module.scss';

function MainShippingWindow() {
	const { order, isExport, isLoading } = useContext(MageOrderContext);

	return (
		<Card className={cx(styles.container)}>
			<div className={cx(styles.scrollContainer)}>
				{order && <OrderDisplay order={order} isExport={isExport} />}
			</div>
			{isLoading && (
				<div className={styles.loader}>
					<div />
				</div>
			)}
		</Card>
	);
}

export default MainShippingWindow;
