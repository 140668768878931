import { PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

interface Props extends PropsWithChildren {
	onClick?: () => void;
	className?: string;
	disabled?: boolean;
	type?: 'submit' | 'button';
}

function Button(props: Props) {
	const { children, onClick, className, disabled, type = 'button' } = props;

	return (
		<button
			className={cx(styles.container, className, {
				[styles.disabled]: disabled,
			})}
			onClick={onClick}
			disabled={disabled}
			type={type}
		>
			{children}
		</button>
	);
}

export default Button;
