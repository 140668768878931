import { HandlerContext } from '../contexts/ActionContext';
import { LogLevel } from '../contexts/LoggerContext';

export const handleLabelPrint = async (
	glsLabel: any,
	scancode: string,
	context: HandlerContext,
) => {
	const { printerApi, addLog } = context;

	if (!glsLabel) {
		addLog('mage', 'No label received', LogLevel.error);

		return false;
	}

	if (glsLabel.message) {
		addLog('mage', glsLabel.message, LogLevel.error);

		return false;
	}

	addLog('app', `Printing Label...`);

	const printState = await printerApi.print(glsLabel, scancode);

	if (!printState) {
		addLog(
			'print',
			`Something went wrong printing the label`,
			LogLevel.warning,
		);

		return false;
	}

	return true;
};
