import { useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Form, Formik } from 'formik';

import { PrinterApi } from '../../classes/PrinterApi';
import { PrinterContext } from '../../contexts/PrinterContext';
import Button from '../Button/Button';
import ConfigViewPage from '../ConfigViewPage/ConfigViewPage';
import FormInput from '../FormInput/FormInput';
import FormSelect from '../FormSelect/FormSelect';

function ConfigViewPrinter() {
	const { config, setConfig, getConfig, printers, setPrinter, printer } =
		useContext(PrinterContext);
	const [cacheStatus, setCacheStatus] = useState<any>(null);

	const getCacheStatus = useCallback(() => {
		const printApi = new PrinterApi(config);

		printApi.getCacheStatus().then((status) => {
			setCacheStatus(status);
		});
	}, [config]);

	useEffect(getCacheStatus, [getCacheStatus]);

	return (
		<ConfigViewPage title='Printer'>
			<Formik
				initialValues={{ ...config, printer: printer }}
				onSubmit={(values, formikProps) => {
					Object.entries(values).forEach(([key, value]) => {
						if (key === 'printer' && value) {
							setPrinter(value.toString());

							return;
						}

						if (!value) {
							localStorage.removeItem(key);

							return;
						}

						localStorage.setItem(key, value.toString());
					});

					setConfig && setConfig(getConfig());
					formikProps.setSubmitting(false);
				}}
			>
				{({ handleSubmit, isSubmitting }) => (
					<Form
						onSubmit={handleSubmit}
						style={{
							display: 'flex',
							flexDirection: 'column',
							paddingBottom: '1rem',
						}}
					>
						<FormInput label='Printer Host' name='printHost' />
						<FormInput label='Printer Port' name='printPort' />
						<FormSelect
							name='printer'
							options={printers}
							noOptions={'Geen printers beschikbaar'}
							label='Printer'
						/>
						<div>
							<Button type='submit' disabled={isSubmitting}>
								Submit
							</Button>
						</div>
					</Form>
				)}
			</Formik>
			{cacheStatus?.cachedFiles > 0 && (
				<>
					<pre>{JSON.stringify(cacheStatus)}</pre>
					<Button
						onClick={async () => {
							const printApi = new PrinterApi(config);
							await printApi.clearCache();
							toast.success('Printer cache cleared');
							getCacheStatus();
						}}
					>
						Clear printer cache
					</Button>
				</>
			)}
		</ConfigViewPage>
	);
}

export default ConfigViewPrinter;
