import { useContext } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { MageOrder, MageProduct } from '../../classes/Magento.types';
import { MyParcelExportData } from '../../classes/MyParcel.types';
import { handleLabelPrint } from '../../classes/Shippr.utils';
import { ActionContext } from '../../contexts/ActionContext';
import { LogLevel } from '../../contexts/LoggerContext';
import { MageOrderContext } from '../../contexts/MageOrderContext';
import Address from '../Address/Address';
import OrderDisplayExportForm from '../OrderDisplayExportForm/OrderDisplayExportForm';
import OrderDisplayItemRow from '../OrderDisplayItemRow/OrderDisplayItemRow';
import OrderDisplayMeta from '../OrderDisplayMeta/OrderDisplayMeta';
import styles from './OrderDisplay.module.scss';

export interface ExportFormData {
	description: string;
	classification: string;
	invoice: string;
	items: { [key: number]: MageProduct };
	itemWeights?: number;
}

interface Props {
	order: MageOrder;
	isExport: boolean;
}

function OrderDisplay(props: Props) {
	const { order, isExport } = props;

	const { setIsLoading } = useContext(MageOrderContext);
	const { context } = useContext(ActionContext);
	const { mageOrderApi, addLog } = context;
	const { customer, method } = order;
	const { shipping, billing } = customer;

	const initialValues: ExportFormData = {
		description: '1. commercial goods',
		classification: '831000000',
		invoice: order.publicId,
		items: {} as { [key: number]: MageProduct },
	};
	order.items.forEach((item) => {
		initialValues.items[item.techId] = item;
	});

	const validate = (values: ExportFormData) => {
		const errors: any = {};

		Object.values(values.items).forEach((item) => {
			if (item.weight <= 0) {
				errors.itemWeights = 'There are items without weight';
			}
		});

		return errors;
	};

	const onSubmit = async (
		values: ExportFormData,
		formikProps: FormikHelpers<ExportFormData>,
	) => {
		const exportData: MyParcelExportData = {
			contents: values.description[0],
			invoice: values.invoice,
			items: Object.values(values.items).map((item) => ({
				description: item.name,
				amount: item.qty.ordered,
				weight: item.weight,
				classification: values.classification,
				country: 'NL',
				value: {
					amount: item.value * 100,
					currency: order.currency,
				},
			})),
		};

		setIsLoading(true);

		let shipmentId = order?.shipments[0];
		if (!order.shipments.length) {
			addLog('app', 'Creating shipment', LogLevel.normal);
			shipmentId = await mageOrderApi.createShipment(order);
			order.shipments[0] = shipmentId;
		}
		const label = await mageOrderApi.getMyParcelExportLabel(
			shipmentId,
			exportData,
			1,
		);

		if (label) {
			handleLabelPrint(label, order.publicId, context);
		}
		setIsLoading(false);

		formikProps.setSubmitting(false);
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
		>
			{({ handleSubmit }) => (
				<Form onSubmit={handleSubmit}>
					<OrderDisplayMeta order={order} />
					<div>
						<div className={styles.address}>
							<Address address={billing} Label={'Billing'} />
							<Address
								address={shipping}
								Label={'Shipping'}
								method={method}
							/>
						</div>
						<div className={styles.productsWrapper}>
							<div className={styles.productsHeader}>
								Products
							</div>
							{order.items.map((item) => (
								<OrderDisplayItemRow
									item={item}
									order={order}
									isExport={isExport}
									key={item.customSku}
								/>
							))}
						</div>
						<div>
							{isExport && (
								<OrderDisplayExportForm order={order} />
							)}
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
}
export default OrderDisplay;
