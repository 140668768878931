import { HandlerContext } from '../contexts/ActionContext';
import { LogLevel } from '../contexts/LoggerContext';

export const getMyParcelLabel = async (
	shipmentId: number,
	orderId: string,
	context: HandlerContext,
) => {
	const { addLog, mageOrderApi, parcelCount } = context;
	addLog(
		'app',
		`Fetching MyParcel label for [${orderId}|${shipmentId}]`,
		LogLevel.bright,
	);

	const myParcelLabel = await mageOrderApi.getMyParcelLabel(
		shipmentId,
		parcelCount,
	);

	if (!myParcelLabel) {
		addLog('app', `No label received!`, LogLevel.error);

		return false;
	}

	const errorMessage = myParcelLabel?.message || myParcelLabel[0]?.message;
	if (errorMessage) {
		addLog('mage', errorMessage, LogLevel.error);

		return false;
	}

	return myParcelLabel;
};
