import ReactCountryFlag from 'react-country-flag';

import { MageProduct } from './Magento.types';

export const storeIdToStore = [
	<></>,
	<>
		<ReactCountryFlag countryCode='nl' svg /> EmailleGigant
	</>,
	<>
		<ReactCountryFlag countryCode='gb' svg /> Quality Enamel Signs
	</>,
	<>
		<ReactCountryFlag countryCode='fr' svg /> Plaques Email
	</>,
	<>
		<ReactCountryFlag countryCode='se' svg /> Emaljskyltar Gatan
	</>,
	<>
		<ReactCountryFlag countryCode='nl' svg /> TopEmaille
	</>,
];

export const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const exportDescriptionType = [
	'1. commercial goods',
	'2. commercial samples',
	'3. documents',
	'4. gifts',
	'5. return shipment',
];

export const calculateOrderItems = (
	items: MageProduct[],
	key: keyof MageProduct,
) => {
	let value = 0;

	items.forEach((item) => {
		// eslint-disable-next-line security/detect-object-injection
		value += item.qty.ordered * item[key];
	});

	return value;
};
