import { useContext } from 'react';

import { AppContext } from '../../contexts/AppContext';
import Action, { ActionProps } from '../Action/Action';
import Card from '../Card/Card';
import styles from './BarActions.module.scss';

interface Props {
	actions?: ActionProps[];
}
export type BarActionsProps = Props;

function BarActions(props: Props) {
	const { actions } = props;

	const { powerMode } = useContext(AppContext);

	return (
		<Card className={styles.container} scrollable>
			{actions &&
				actions.map((action, i) => {
					if (action.isPowerMode && !powerMode) {
						return null;
					}

					return (
						<>
							{((actions[i - 1] &&
								actions[i - 1].group !== action.group) ||
								i === 0) && (
								<span
									style={{
										marginTop: i === 0 ? '0' : '1.2rem',
										marginBottom: '0.4rem',
										textTransform: 'capitalize',
									}}
								>
									{action.group}
								</span>
							)}
							<Action
								icon={action.icon}
								key={action.actionId}
								actionId={action.actionId}
								label={action.label}
								isPowerMode={action.isPowerMode}
								handler={action.handler}
								group={action.group}
							/>
						</>
					);
				})}
		</Card>
	);
}

export default BarActions;
