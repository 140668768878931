import { PropsWithChildren } from 'react';
import cx from 'classnames';

import styles from './Card.module.scss';

enum CardType {
	'default' = 'default',
	'warning' = 'warning',
	'error' = 'error',
}

interface Props extends PropsWithChildren {
	className?: string;
	type?: keyof typeof CardType;
	scrollable?: boolean;
}

function Card(props: Props) {
	const { children, className, type, scrollable } = props;

	return (
		<div
			className={cx(styles.container, className, {
				[styles.warning]: type === CardType.warning,
				[styles.error]: type === CardType.error,
				[styles.scrollable]: scrollable,
			})}
		>
			{children}
		</div>
	);
}

export default Card;
