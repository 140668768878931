import { MagentoApi } from "./MagentoApi";
import { WarehouseStockProduct } from "./Warehouse.utils";

export class MagentoWarehouseApi extends MagentoApi {
	public async getProductData(
		sku: string,
	): Promise<{ [key: string]: string }> {
		const data = await this._call(`shippr/product/${sku}`);

		if (data && data[0]) {
			return data[0];
		}

		return data;
	}

	public async getFullProductData(
		sku: string,
	): Promise<WarehouseStockProduct> {
		const data = await this._call(`shippr/product/${sku}/stock`);

		if (data && data[0]) {
			return data[0];
		}

		return data;
	}

	public async updateProduct(sku: string, payload: {qty: number}) {
		const data = await this._call(`shippr/product/${sku}/stock`, payload);

		if (data && data[0]) {
			return data[0];
		}

		return data;
	}
}