import { createContext, PropsWithChildren, useState } from 'react';

export enum LogLevel {
	normal = 'lightsteelblue',
	bright = 'white',
	error = 'tomato',
	warning = 'orange',
	success = 'lime',
}

interface LoggerLog {
	type: string;
	message: string;
	level: LogLevel;
}

interface LoggerState {
	logs: LoggerLog[];
	addLog: (type: string, newLog: string, logLevel?: LogLevel) => void;
}

const defaultLoggerContext: LoggerState = {
	logs: [],
	addLog: (type: string, newLog: string, logLevel?: LogLevel) => {
		console.debug({ type }, { newLog }, { logLevel });
	},
};

export const LoggerContext = createContext(defaultLoggerContext);

const audio = {
	success: new Audio('/success.wav'),
	error: new Audio('/error.wav'),
};
audio.success.volume = 0.3;
audio.error.volume = 0.3;

const maxLogEntries = 100;

function LoggerContextProvider(props: PropsWithChildren) {
	const { children } = props;

	const [logs, setLogs] = useState([] as LoggerLog[]);

	function addLog(
		type: string,
		newLog: string,
		logLevel: LogLevel = LogLevel.normal,
	) {
		setLogs((prevLogs: LoggerLog[]) => {
			const logArray = [...prevLogs].slice((maxLogEntries - 1) * -1);
			logArray.push({
				type: type.toUpperCase(),
				message: newLog,
				level: logLevel || LogLevel.normal,
			});

			return logArray;
		});

		if (logLevel === LogLevel.success) {
			audio.success.play();
		}

		if (logLevel === LogLevel.error || logLevel === LogLevel.warning) {
			audio.error.play();
		}
	}

	const context = {
		logs,
		addLog,
	};

	return (
		<LoggerContext.Provider value={context}>
			{children}
		</LoggerContext.Provider>
	);
}

export default LoggerContextProvider;
