import { useContext } from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

import { days, storeIdToStore } from '../../classes/Constants';
import { MageOrder } from '../../classes/Magento.types';
import { MageContext } from '../../contexts/MageContext';
import DecoratedText from '../DecoratedText/DecoratedText';
import styles from './OrderDisplayMeta.module.scss';

interface Props {
	order: MageOrder;
}

function OrderDisplayMeta(props: Props) {
	const { order } = props;

	const { config } = useContext(MageContext);
	const { siteUrl, adminBase } = config;
	const { publicId, techId, storeId, date: orderDate, customer } = order;
	const { firstname, lastname, email, phone } = customer;

	return (
		<div className={styles.container}>
			<div>
				<div className={styles.store}>
					{storeIdToStore[storeId ?? 0]}
				</div>
				<div>
					<span className={styles.publicId}>{publicId}</span>
					<span className={styles.techId}>[{techId}]</span>
				</div>
				<div>
					{days[orderDate.getUTCDay()]} {orderDate.toLocaleString()}
				</div>
			</div>
			<div>
				<div className={styles.mageLink}>
					<a
						href={`${siteUrl}/${adminBase}/sales/order/view/order_id/${techId}`}
						target={'_blank'}
						rel='noopener noreferrer'
					>
						View order on Magento
					</a>
				</div>
				<div className={styles.customer}>Customer:</div>
				<div>
					{firstname} {lastname}
				</div>
				<DecoratedText Icon={FaEnvelope}>{email}</DecoratedText>
				<DecoratedText Icon={FaPhone}>{phone}</DecoratedText>
			</div>
		</div>
	);
}

export default OrderDisplayMeta;
