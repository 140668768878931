import { ReactNode } from 'react';
import cx from 'classnames';

import Button from '../Button/Button';
import styles from './ConnectionStatus.module.scss';

interface Props {
	notifyStatus: () => void;
	renderIcon: () => ReactNode;
}

function ConnnectionStatus(props: Props) {
	const { notifyStatus, renderIcon } = props;

	return (
		<Button className={cx(styles.container)} onClick={notifyStatus}>
			{renderIcon()}
		</Button>
	);
}

export default ConnnectionStatus;
