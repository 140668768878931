import { useContext, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import QrCodeWithLogo from 'qrcode-with-logos';

import { MageContext } from '../../contexts/MageContext';
import Button from '../Button/Button';
import ConfigViewPage from '../ConfigViewPage/ConfigViewPage';
import FormInput from '../FormInput/FormInput';
import styles from './ConfigViewStore.module.scss';

const configQrWidth = 250;

function ConfigViewStore() {
	const { config, setConfig, getConfig } = useContext(MageContext);
	const imageRef = useRef<HTMLImageElement>(null);

	useEffect(() => {
		new QrCodeWithLogo({
			content: `[config]:${JSON.stringify(config)}`,
			width: configQrWidth,
			image: imageRef?.current ?? undefined,
			nodeQrCodeOptions: {
				errorCorrectionLevel: 'H',
			},
		}).toImage();
	}, [imageRef, config]);

	return (
		<ConfigViewPage title='Store'>
			<Formik
				initialValues={config}
				onSubmit={(values, formikProps) => {
					Object.entries(values).forEach(([key, value]) => {
						localStorage.setItem(key, value);
					});

					setConfig && setConfig(getConfig());
					formikProps.setSubmitting(false);
				}}
			>
				{({ handleSubmit, isSubmitting }) => (
					<Form onSubmit={handleSubmit}>
						<FormInput label='Site URL' name='siteUrl' />
						<FormInput label='Rest Base' name='restBase' />
						<FormInput label='Access Token' name='accessToken' />
						<div className={styles.formAdminBase}>
							<FormInput label='Admin Base' name='adminBase' />
						</div>
						<Button type='submit' disabled={isSubmitting}>
							Submit
						</Button>
					</Form>
				)}
			</Formik>

			{config.accessToken && (
				<div className={styles.qr}>
					<span>Scan this code with the app to copy the config</span>
					<img
						ref={imageRef}
						style={{
							maxWidth: `${configQrWidth}px`,
							paddingBottom: '1rem',
						}}
					/>
				</div>
			)}
		</ConfigViewPage>
	);
}

export default ConfigViewStore;
