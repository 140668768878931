import { HandlerContext } from '../contexts/ActionContext';
import { LogLevel } from '../contexts/LoggerContext';

export const getGlsLabel = async (
	shipmentId: number,
	orderId: string,
	context: HandlerContext,
) => {
	const { addLog, mageOrderApi, parcelCount } = context;
	addLog(
		'app',
		`Fetching GLS label for [${orderId}|${shipmentId}]`,
		LogLevel.bright,
	);

	const glsLabel = await mageOrderApi.getGlsLabel(shipmentId, parcelCount);

	if (!glsLabel) {
		addLog('app', `No label received!`, LogLevel.error);

		return false;
	}

	const errorMessage = glsLabel?.message || glsLabel[0]?.message;
	if (errorMessage) {
		addLog('mage', errorMessage, LogLevel.error);

		return false;
	}

	return glsLabel;
};
